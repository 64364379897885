.description-window-handler {
    background-color: var(--light-purple);
    display: flex;

}

.description-window-handler:hover {
    cursor: ew-resize;
    background-color: var(--off-white);
}

.description-window-handler:active {
    cursor: ew-resize;
    background-color: var(--off-white);
}