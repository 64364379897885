.description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2.6rem 1fr;
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 0.3rem;
    user-select: none;
    text-align: justify;
}

.description-content-wrapper {
    overflow: scroll;
    padding: 0.78rem;
}

.description-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-x: auto;
}
  
.sub-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sub-header-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--light-purple);
    color: var(--primary-purple);
}

.sub-header-div:hover {
    cursor: pointer;
}

.active-sub-header {
    border-bottom: 2px solid var(--primary-purple);
}

.description-title {
    /* font-weight: 200; */
    padding: 0.32rem 0 0 0;
    color: var(--dark-purple);
    text-align: left;
}

.description-content-inside {
    padding: 1rem 0;
    color: var(--dark-purple);
    position: relative;
}

.primary-purple {
    color: var(--primary-purple);
}

.primary-pink {
    color: var(--primary-pink);
}

.description-content-box {
    background-color: var(--light-purple);
    border-radius: 0.6rem;
    padding: 1rem;
    margin: 1rem 0;
}

.success-box {
    background-color: var(--background-green);
    border-radius: 0.3rem;
    padding: 0 0.3rem;
}

.in-progress-box {
    background-color: var(--background-in-progress);
    border-radius: 0.3rem;
    padding: 0 0.3rem;
}

.error-box {
    background-color: var(--background-error);
    border-radius: 0.3rem;
    padding: 0 0.3rem;
}

.code {
    overflow-x: scroll;
    white-space: nowrap;
}

.procedure-line::before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 2.47rem;
    width: 1px;
    height: 13.52rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.outer-for-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 9rem;
    width: 1px;
    height: 13rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.inner-for-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 4.7rem;
    top: 14.2rem;
    width: 1px;
    height: 5.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.if-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 6.3rem;
    top: 16.8rem;
    width: 1px;
    height: 2.6rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.output-array-field {
    border: none; /* Default border */
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border-radius: 0.3rem; 
    padding: 0.6rem 1.2rem; 
    font-size: 1.6rem; 
    border: 1px solid var(--light-purple);
    transition: border-color 0.3s ease-in-out; /* Add smooth transition */
    outline: none;
    overflow-x: scroll;
}

.output-array-field:focus {
    border-color: var(--dark-purple);
}