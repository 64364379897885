.animation {
    display: grid;
    grid-template-columns: 1fr;
    overflow-x: hidden;
    line-height: 1;
    background-color: white;
    border-radius: 0.3rem;
  }

.animation-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--light-purple);
}

.animation .flex-container {
    display: flex;
    flex-direction: row;
}
  
.animation-header .flex-item {
    width: auto;
}

.animation-header .playback-speed {
    padding: 2px 10px;
    height: 90%;
}

.animation-header .playback-speed:hover {
    cursor: pointer;
}

.animation-header .restart-button {
    padding: 2px 10px;
    height: 80%;
}
.animation-header .restart-button:hover {
    cursor: pointer;
}

.animation-header .fullscreen-toggle {
    padding: 2px 10px;
    height: 80%;
}

.animation-header .fullscreen-toggle:hover {
    cursor: pointer;
}

.animation-header-control-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.animation-progress-bar-background {
    background-color: white;
    padding: 0 1.2rem;
    height: 0.3rem;
    margin: 0.6rem 0;
}

.animation-progress-bar-background>div {
    background-color: var(--background-purple);
    justify-self: center;
}

.animation-progress-bar{
    background-color: var(--primary-purple);
}

.reset-to-default{
    height:auto;
}

.apply {
    height: auto;
}

.input-array {
    display: flex;
    align-items: center;
}

.status-info-button {
    height: auto;
    text-wrap: nowrap;
    white-space: nowrap;
    display: flex;
    padding: 0.1rem 1.2rem;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border: 0;
    border-radius: 0.2rem;
    margin: 0.4rem 1.2rem;
    font-size: 1.6rem;
    align-items: center;
}