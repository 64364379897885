/* .container { */
  /* display: grid; */
  /* grid-template-columns: 0px 1fr 1fr 1fr 0px; */
  /* grid-template-rows: 7rem 0px 1fr 1fr 0px; */
  
  /* gap:10px; */
  /* background-color: var(--light-purple); */
/* } */

.container-app {
  display: grid;
  grid-template-columns: 6.5px 1fr 1fr 1fr 6.5px;
  grid-template-rows: 2.6rem 6.5px 1fr 1fr 6.5px;
  
  /* gap:10px; */
  background-color: var(--light-purple);
}

/* .container div { */
  /* border: 1px solid var(--black); */
/* } */

.header {
  grid-column-start: 1;
  grid-column-end: 6;
}

.home-header {
  height: 5rem;
}

.content-container {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 5;

  display: grid;
  grid-template-rows: 1fr;

  background-color: var(--light-purple);
}

.indent-1 {
  padding-left: 1rem;
}

.indent-2 {
  padding-left: 2rem;
}

.indent-3 {
  padding-left: 3rem;
}

.indent-4 {
  padding-left: 4rem;
}

.indent-5 {
  padding-left: 5rem;
}