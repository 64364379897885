.animation-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
}

.animation-step-control-container {
    width: auto;
    height: auto;
    margin: 1.2rem;
}

.control-window-buttons {
    width: auto;
    height: auto;
    display: flex;
    white-space: nowrap;
}

.custom-inputs-button {
    height: auto;
    display: flex;
    padding: 0.2rem 1rem;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border: 0;
    border-radius: 0.3rem;
    margin: 0.2rem 0.6rem;
    /* font-size: 1.6rem; */
}

.start-animation-button {
    height: auto;
    width: auto;
    padding: 0.2rem 1rem;
    background-color: var(--primary-green);
    color: white;
    border: 0;
    border-radius: 0.3rem;
    margin: 0.2rem 0.6rem;
    /* font-size: 1.6rem; */
}

.animation-step-control-container svg:hover {
    cursor: pointer;
}