.first-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner-section {
  background-color: #F1F2F9; 
  padding: 2.4rem; 
  font-style: italic; 
  font-size: 1.2rem;
  text-align: center;
  height: fit-content;
}

.features-content-section {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.features-right-section-margin {
  margin-left: 5rem;
}

.features-image-section {
  height: fit-content; 
  display: flex;
  justify-content: center;
  align-self: center;
}

.hero-title {
  font-weight: 700;
  color: var(--primary-purple);
  height: fit-content;
}

.first-section-content {
  margin: 0.8rem 0;
  color: var(--primary-purple);
  height: fit-content;
}

.features-content {
  color: var(--primary-purple);
  height: fit-content;
}

.get-started-button {
  height: auto;
  width: auto;
  padding: 0.4rem 2rem;
  background-color: var(--primary-pink);
  color: white;
  border: 0;
  border-radius: 0.3rem;
  margin: 0.8rem auto 0.8rem 0;
  border-radius: 4rem;
}


.right-section-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.windows-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.window {
  flex: 0 0 auto;
  border: 0.2rem solid var(--primary-purple);
  border-radius: 2rem;
  width: 15rem;
  height: 12rem;
  margin: 1.2rem;
  overflow: hidden;
}

.windows-row {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;
}

.windows-row:nth-child(2) {
  padding-left: 4.6rem;
}

.footer-section {
  background-color: #F1F2F9; 
  padding: 1.2rem; 
  font-style: italic; 
  text-align: center;
  height: fit-content;
}

.menu-items-for-mobile {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}