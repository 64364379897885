.header .flex-container {
    display: flex;
    flex-direction: row;
    background-color: var(--white);
}
  
.header .flex-item {
    width: auto;
}

.header .flex-item1 {
    flex: 0 0 auto;
    padding: 1.2rem;
}

.header .flex-item1-app {
    flex: 0 0 auto;
    padding: 0.5rem 1.6rem;
}

.header .flex-item2 {
    flex-grow: 1;
    display: flex;
    flex-grow: 1;
}

.header .flex-item3 {
    flex: 0 0 auto;
    padding: 1.2rem;
}

.header .flex-item3-app {
    flex: 0 0 auto;
    padding: 0.5rem 1.6rem;
}

.geeko-color{
    color: var(--primary-pink);
}

.sophers-color {
    color: var(--primary-purple);
}

.flex-align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu-items-flex-display {
    display: flex;
    flex-grow: 1;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .hide-display-in-mobile {
        display: none;
    }

    .height-fit-content-sm {
        height: fit-content;
    }

    .show-padding-sm {
        padding: 4.2rem;
    }
}

@media only screen and (min-width: 768px) {
    .hide-display-in-laptop {
        display: none;
    }
}

.hamburger {
    cursor: pointer;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s,-webkit-transform .4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    right: 0;
    top: 0;
}

.hamburger-top {
    stroke-dasharray: 40 139;
}

.hamburger-line {
    fill: none;
    stroke: #a5a5a5;
    stroke-width: 3;
    -webkit-transition: stroke-dasharray .4s,stroke-dashoffset .4s;
    transition: stroke-dasharray .4s,stroke-dashoffset .4s;
}

.hamburger-active {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger-active .hamburger-top {
    stroke-dashoffset: -98px;
}

.hamburger-menu {
    display: block;
}

.hamburger-spacing {
    margin: 0 1.2rem; 
    padding: 1rem 0 0 0;
}

.hamburger-spacing-app {
    margin: 0 1.2rem; 
    padding: 0.4rem 0 0 0;
}