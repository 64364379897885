.animation-controls-wrapper {
    display: grid;
    grid-template-columns: 1fr;
}

.custom-input-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr auto;
    padding: 1.2rem;
}

.custom-input-border {
    background-color: var(--light-purple);
}

.custom-input-action-buttons {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    padding: 1.2rem 0 0 0;
    align-items: center;
}

.input-array {
    padding: 0.6rem 0;
    color: var(--dark-purple);
}

.input-array-field {
    border: none; /* Default border */
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border-radius: 0.3rem; 
    padding: 0.3rem 0.6rem; 
    /* font-size: 1.6rem;  */
    border: 1px solid var(--light-purple);
    transition: border-color 0.3s ease-in-out; /* Add smooth transition */
    outline: none;
}

.input-array-field:focus {
    border-color: var(--dark-purple);
}

.reset-to-default {
    /* height: auto; */
    background-color: var(--light-purple);
    color: var(--dark-purple);
    border: 0;
    padding: 0.6rem;
    border-radius: 0.3rem;
    /* font-size: 1.6rem; */
    width: auto;
    margin-right: 1.2rem;
}

.apply {
    /* height: auto; */
    background-color: var(--dark-purple);
    color: white;
    border: 0;
    border-radius: 0.3rem;
    padding: 0.6rem;
    /* font-size: 1.6rem; */
    width: auto;
    margin-left: 1.2rem;
}