.bst-node {
    width: 4rem;
    height: 4rem;
    background-color: var(--background-purple);
    color: var(--dark-purple);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    border: solid 0.3rem var(--background-purple);
}
  
.bst-edge {
    position: absolute;
    height: 0.2rem;
    width: 4rem;
    background-color: var(--background-purple);
}

.tree-box {
    width: 1rem;
    height: 1rem;
    top: 2rem;
    margin: auto;
    position: relative;
}

.min-procedure-line::before {
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 3.8rem;
    width: 1px;
    height: 16rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.min-if-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 6.2rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.min-while-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 14rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}