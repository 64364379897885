/* Most of the css proprties are taken from selection sort animation */


.insertion-procedure-line::before {
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 3.8rem;
    width: 1px;
    height: 28.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.insertion-for-loop::before {
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 9rem;
    width: 1px;
    height: 20.8rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.insertion-while-loop::before {
    content: '';
    position: absolute;
    left: 4.7rem;
    top: 19.4rem;
    width: 1px;
    height: 5.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}