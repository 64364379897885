.tree-traversal-procedure-line::before {
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 3.6rem;
    width: 1px;
    height: 13.8rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.pre-order-if-left-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 8.6rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.pre-order-if-right-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 13.8rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.post-order-if-left-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 6rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.post-order-if-right-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 11.2rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.in-order-if-left-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 6rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.in-order-if-right-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 13.8rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.related-topic {
    background-color: var(--light-purple);
    padding: 0.2rem 1.2rem;
    border-radius: 2.4rem;
    width: fit-content;
    font-size: 1.4rem;
    margin: 0.4rem;   
    white-space: nowrap;
}