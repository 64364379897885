.animation-world {
    overflow: scroll;
    position: relative;
}

.centering-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    min-width: 100%; 
    min-height: 100%;
}

.box-container {
    height: fit-content;
    width: fit-content;
    display: flex;
    position: relative;
}

.box{
    width: 5.2rem;
    height: 5.2rem;
    
    position: relative;
    /* top: 3rem; */
    margin: 0 1rem 0 0;
    
    background-color: var(--background-purple);
    color: var(--dark-purple);
    border-radius: 0.3rem;
}

.box-elem {
    display: flex;
    align-items: center; 
    justify-content: center; 
}

.pointer {
    width: 5.2rem;
    height: 1rem;
    position: absolute;
    top: 5.2rem;
    background-color: var(--primary-purple);
    border-radius: 0.3rem;
}


@media only screen and (max-width: 768px) {
    .scaling-for-mobile {
        transform: scale(0.65);
    }
}