.comment-color {
    color: #aaa
}

.mps-function-line::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 3.8rem;
    width: 1px;
    height: 56rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.mps-inner-line-1::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 26.6rem;
    width: 1px;
    height: 5.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.mps-inner-line-2::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 36.8rem;
    width: 1px;
    height: 5.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.mps-inner-line-3::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 47.2rem;
    width: 1px;
    height: 7.8rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.matrix {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.mps-box{
    width: 8rem;
    height: 8rem;
    
    position: relative;
    border: 0.1rem solid white;
    
    background-color: var(--background-purple);
    color: var(--dark-purple);
    border-radius: 0.3rem;
}

.left-box-container-margin {
    margin-right: 8rem;
}

.mps-progress-window {
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 0rem;
    background-color: rgba(248,224,195,0.2);
    /* opacity: 0.5; */
    border-radius: 0.3rem;
    border: 0.3rem solid var(--background-in-progress);
}

.status-info-box{
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--light-purple);
    color: var(--dark-purple);
    margin: 0.4rem 1.2rem;
    line-height: 1.6;
    width: fit-content;
    padding: 1.2rem;
    border-radius: 0.3rem;
}