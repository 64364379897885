/* Most of the css proprties are taken from selection sort animation */


.bubble-procedure-line::before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 2.4rem;
    width: 1px;
    height: 17rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.bubble-repeat::before {
    content: '';
    position: absolute;
    left: 2rem;
    top: 5.4rem;
    width: 1px;
    height: 12.5rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.bubble-for-loop::before {
    content: '';
    position: absolute;
    left: 3rem;
    top: 8.4rem;
    width: 1px;
    height: 6.5rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.bubble-if-loop::before {
    content: '';
    position: absolute;
    left: 4rem;
    top: 10rem;
    width: 1px;
    height: 3.5rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}