:root {
  /* Colors */
  --minimum: #F8E0C3;
  --success: #61B753;
  --error: #F1BFBF; 
  --current-item: #C1E2F4;
  --aqua: #00ffff;
  --white: #ffffff;
  --black: #000000;
  --off-white: #eee;

  --primary-purple: #4B5399;
  --dark-purple: #050829;
  --background-purple: #BABFEA;
  --light-purple: #F1F2F9;

  --primary-pink: #B7537A;
  --background-pink: #F2C8D8;

  --primary-green: #61B753;
  --background-green: #D1F6CB;

  --background-in-progress: #F8E0C3;
  --primary-in-progress: #C28E4F;
  
  --background-error: #F1BFBF;

  /* Font Size */
  --font-size-large: 1.8rem;
  --font-size-small: 1.4rem;
  
  /* Spacing */
  --s-size: 1.2rem;
  --m-size: 1.6rem;
  --l-size: 3.2rem;
  --xl-size: 4.8rem;
  --desktop-breakpoint: 45rem;
  --mobile-breakpoint: 20rem;
}

* {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  letter-spacing: 0.08rem;
}

body {
  /* font-size: 1.6rem;
  line-height: 1.6; */
}

svg {
  width: 99%; 
  height: 99%; 
}

html {
  /* font-size: 62.5%; */
}

.is-active {
  font-weight: bold;
}

button:disabled {
  cursor: default;
}

button {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}