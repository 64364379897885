.lca-procedure-line::before {
    content: '';
    position: absolute;
    left: 1.5rem;
    top: 3.6rem;
    width: 1px;
    height: 21rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.lca-first-if-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 6rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.lca-second-if-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 11.2rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.lca-else-if-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 16.4rem;
    width: 1px;
    height: 3.2rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}

.lca-else-loop::before { /* A space is 0.4 rem approx */
    content: '';
    position: absolute;
    left: 3.1rem;
    top: 21.6rem;
    width: 1px;
    height: 3rem;
    background-color: var(--background-purple);
    transform: translateX(-50%);
    z-index: 1;
}