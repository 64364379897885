.tags {
    height: auto;
    width: auto;
    padding: 0.4rem 2rem;
    background-color: var(--background-purple);
    color: var(--primary-purple);
    border: 0;
    border-radius: 0.3rem;
    margin: 0.8rem 1.2rem 0.8rem 0;
    border-radius: 4rem;
}

.play-animation-button {
    height: auto;
    width: auto;
    padding: 0.6rem 2rem;
    background-color: var(--primary-pink);
    color: white;
    border: 0;
    border-radius: 0.3rem;
    margin: 0.8rem 1.2rem 0.8rem 0;
    border-radius: 4rem;
}